import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import PortableText from '@sanity/block-content-to-react'
import getYouTubeId from 'get-youtube-id'
import urlBuilder from '@sanity/image-url'
import { window } from 'browser-monads'
import YouTube from 'react-youtube'
import {
  TwitterShareButton,
  EmailShareButton,
  LinkedinShareButton,
  FacebookShareButton,
} from 'react-share'

import EmailIcon from 'svgs/email.svg'
import TwitterIcon from 'svgs/twitter.svg'
import FacebookIcon from 'svgs/facebook.svg'
import LinkedInIcon from 'svgs/linkedin.svg'
import GetImage from 'components/functional/getImage'
import SubHeader from 'components/shared/subHeader'
import { Layout } from 'components/global'
import { media } from 'utils/media'

const Blog = ({ data }) => {
  const blogData = data.Blog
  const coverImage = blogData.mainImage

  const pageMeta = {
    title: `${blogData.title} | Arnold Clark Cup`,
    description: blogData.description,
    img: blogData.mainImage.asset.url,
  }
  return (
    <Layout
      title={pageMeta.title}
      description={pageMeta.description}
      image={blogData.mainImage.asset.url}
      background
    >
      {/* <SubHeader /> */}
      <BlogHeader coverImage={coverImage.asset} />
      <main>
        <BlogBody blogData={blogData} />
      </main>
    </Layout>
  )
}

export default Blog

const BlogHeaderStyles = styled.header`
  width: 100%;
  background-color: #fff;
  padding-top: 95px !important;
  .bheader {
    max-width: 1200px;
    margin: 0 auto;
    .gatsby-image-wrapper {
      height: auto;
      width: 100%;
      max-height: 800px;
    }
  }
`
const BlogHeader = ({ coverImage }) => (
  <BlogHeaderStyles>
    <div className="bheader">
      <GetImage data={coverImage} />
    </div>
  </BlogHeaderStyles>
)

const urlFor = source =>
  urlBuilder({
    projectId: 'm3n3vmpd',
    dataset: 'production',
  }).image(source)

const BlogBodyStyles = styled.article`
  width: 100%;
  background-color: #fff;
  .bb {
    width: 90%;
    margin: 0 auto;
    max-width: 1200px;
    padding-bottom: 4rem;
    &__left {
      padding-top: 1.5rem;
      &--title {
        h5 {
          font-weight: 700;
          color: var(--darkGrey);
        }
        h1 {
          margin: 0.75rem 0;
          color: var(--darkGrey);
          font-size: 1.85rem;
          @media ${media.md} {
            font-size: 2rem;
            margin-top: 0;
          }
          @media ${media.xl} {
            font-size: 3rem;
            margin-top: 0;
          }
        }
        span {
          color: var(--darkGrey);
          font-weight: 700;
          display: inline-block;
        }
        p {
          margin-right: 5px;
          display: inline-block;
        }
      }
      &--body {
        margin-top: 1.5rem;
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          margin: 1rem 0;
        }
        strong {
          color: #000;
        }
        p {
          line-height: 1.45;
          font-size: var(--text);
          margin: 1.2rem 0;
          color: var(--darkGrey);
        }
        ul {
          list-style: inside;
        }
        ol {
          list-style-position: inside;
        }
        li {
          margin: 0.5rem 0;
          line-height: 1.45;
          color: #000;
        }
        a {
          color: var(--teal);
          font-weight: 800;
        }
        .blog-image {
          width: 100%;
          height: auto;
          img {
            width: 100%;
            height: auto;
          }
        }
        .image {
          img {
            width: 100%;
            height: auto;
          }
        }
        iframe {
          width: 100%;
        }
      }
    }
    &__right {
      margin-top: 1rem;
      &--share {
        width: 100%;
        padding: 2rem;
        background-color: var(--darkGrey);
        p {
          text-align: center;
          margin-bottom: 1rem;
          font-size: 1.45rem;
          font-weight: 800;
          color: var(--offWhite);
        }
        &__wrapper {
          display: flex;
          justify-content: space-evenly;
          gap: 0.5rem;
          button {
            display: flex !important;
            align-items: center !important;
            justify-content: center !important;
            border-radius: 50% !important;
            width: 40px !important;
            height: 40px !important;
            transition: all 0.25s ease-in-out !important;
            cursor: pointer !important;

            &:nth-child(1) {
              background-color: var(--purple) !important;
              border: solid 3px var(--purple) !important;
              &:hover,
              &:active {
                border: solid 3px var(--purple);
                background-color: transparent !important;
                svg {
                  fill: var(--purple);
                }
              }
            }
            &:nth-child(2) {
              background-color: var(--blue) !important;
              border: solid 3px var(--blue) !important;
              &:hover,
              &:active {
                border: solid 3px var(--blue);
                background-color: transparent !important;
                svg {
                  fill: var(--blue);
                }
              }
            }
            &:nth-child(3) {
              background-color: var(--pink) !important;
              border: solid 3px var(--pink) !important;
              &:hover,
              &:active {
                background-color: transparent !important;
                svg {
                  fill: var(--pink);
                }
              }
            }
            &:nth-child(4) {
              background-color: var(--teal) !important;
              border: solid 3px var(--teal) !important;
              &:hover,
              &:active {
                background-color: transparent !important;
                svg {
                  fill: var(--teal);
                }
              }
            }
            div {
              display: flex;
              align-items: center;
              justify-content: center;
              transition: all 0.25s ease-in-out !important;
            }
            svg {
              transition: all 0.25s ease-in-out !important;
              width: 20px;
              height: 20px;
              fill: var(--offWhite);
            }
          }
        }
      }
    }
    @media only screen and (min-width: 750px) {
      display: grid;
      grid-template-columns: 65% 1fr;
      grid-gap: 1.5rem;
      position: relative;
      .sticky {
        position: sticky;
        top: 8rem;
      }
      &__right {
        position: static;
        top: 1rem;
        height: 100%;
        &--author {
          bottom: 5rem;
          display: block;
          section {
            width: 80px;
            margin: 0 auto 0.5rem auto;
            .gatsby-image-wrapper {
              width: 100%;
              height: auto;
            }
          }
          div {
            text-align: center;
          }
        }
        &--share {
        }
      }
    }
    @media only screen and (min-width: 1280px) {
      width: 70%;
      padding-top: 2rem;
    }
    @media ${media.xl} {
      button {
        width: 55px !important;
        height: 55px !important;
        svg {
          width: 25px;
          height: 25px;
        }
      }
    }
  }
`
const BlogBody = ({ blogData }) => {
  console.log(blogData)
  const serializers = {
    types: {
      image: props => (
        <div className="blog-image">
          <img
            src={`${urlFor(props.node.asset)
              .width(1388)
              .height(926)
              .fit('crop')
              .auto('format')}`}
            alt={blogData.title}
          />
        </div>
      ),
      videoEmbed: ({ node }) => {
        const { url } = node
        const id = getYouTubeId(url)
        return <YouTube videoId={id} className="video" />
      },
    },
  }
  return (
    <BlogBodyStyles>
      <div className="bb">
        <div className="bb__left">
          <div className="bb__left--title">
            <h1>{blogData.title}</h1>
            {/* <p>{` ${blogData.publishedAt} :`}</p>
            <span>{`${blogData.readingTimeInMinutes} min read`}</span> */}
          </div>
          <div className="bb__left--body">
            <PortableText
              blocks={blogData._rawBody}
              serializers={serializers}
            />
          </div>
        </div>
        <div className="bb__right">
          <div className="sticky">
            <div className="bb__right--share">
              <p>Share this: </p>
              <div className="bb__right--share__wrapper">
                <EmailShareButton
                  url={window.location.href}
                  subject={blogData.title}
                >
                  <div className="share--box email">
                    <EmailIcon />
                  </div>
                </EmailShareButton>
                <TwitterShareButton
                  url={window.location.href}
                  title={blogData.title}
                >
                  <div className="share--box twitter">
                    <TwitterIcon />
                  </div>
                </TwitterShareButton>
                <LinkedinShareButton
                  url={window.location.href}
                  summary={blogData.excerpt}
                  source="UCtel Blog"
                >
                  <div className="share--box linkedin">
                    <LinkedInIcon />
                  </div>
                </LinkedinShareButton>
                <FacebookShareButton
                  url={window.location.href}
                  quote={blogData.title}
                >
                  <div className="share--box facebook">
                    <FacebookIcon />
                  </div>
                </FacebookShareButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BlogBodyStyles>
  )
}

export const query = graphql`
  query SingleBlogQuery($slug: String!) {
    Blog: sanityPost(slug: { current: { eq: $slug } }) {
      title
      description
      slug {
        current
      }
      publishedAt(formatString: "MMM DD YYYY")
      id
      _rawBody

      mainImage {
        asset {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          url
        }
      }
    }
  }
`
